import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import FieldSet from '../../../components/Form/FieldSet'
import Form from '../../../components/Form/Form'
import HiddenInput from '../../../components/Form/Inputs/HiddenInput'
import PasswordInput from '../../../components/Form/Inputs/PasswordInput'
import SelectInput from '../../../components/Form/Inputs/SelectInput'
import SwitchInput from '../../../components/Form/SwitchInput'
import SidePanel from '../../../components/SidePanel/SidePanel'
import SidePanelBody from '../../../components/SidePanel/SidePanelBody'
import SidePanelFooter from '../../../components/SidePanel/SidePanelFooter'
import SidePanelHeader from '../../../components/SidePanel/SidePanelHeader'
import { CURRENCIES } from '../../../data/Currencies'
import { processorByCode, PROCESSORS, PROCESSOR_MODES, TRANSACTION_TYPES } from '../../../data/ProcessorCredentials'
import gatewayApi from '../../../services/gatewayApi'

export default function NewProcessor() {
  const navigate = useNavigate()
  const { merchantId } = useParams()
  const [open, setOpen] = useState(false)
  const [processorType, setProcessorType] = useState(null)
  const submitRef = useRef()

  useEffect(() => {
    setOpen(true)
  }, [])

  const selectProcessor = (processorCode) => {
    const processor = processorByCode(processorCode)
    setProcessorType(processor)
  }

  const onSubmit = (data) => {
    console.log('data', data)
    gatewayApi
      .post('/app/admin/processors', { processor: data })
      .then((r) => console.log('r', r))
      .catch((e) => console.log('e', e))
  }
  const onError = (error) => console.log('error', error)

  return (
    <div>
      <SidePanel open={open} setOpen={setOpen} onClose={() => navigate(`/admin/merchant/${merchantId}/processors`)}>
        <div>
          <SidePanelHeader onClose={() => setOpen(false)} title="Create Processor" />
          <SidePanelBody>
            <Form
              className="divide-y divide-gray-100 py-0 sm:space-y-0 sm:divide-gray-200 sm:py-0"
              submitRef={submitRef}
              onSubmit={onSubmit}
              onError={onError}
            >
              <FieldSet label="Alias" name="alias" rules={{ required: 'This field is required.' }} />
              <FieldSet label="MID Number" name="mid_number" rules={{ required: 'This field is required.' }} />
              <FieldSet
                name="currency"
                label="Currency"
                rules={{ required: 'This field is required.' }}
                input={<SelectInput options={CURRENCIES} />}
              />
              <FieldSet
                name="mode"
                label="Processor Mode"
                rules={{ required: 'This field is required.' }}
                input={<SelectInput options={PROCESSOR_MODES} />}
              />
              <FieldSet
                name="transaction_type"
                label="Supported Transactions"
                rules={{ required: 'This field is required.' }}
                input={<SelectInput options={TRANSACTION_TYPES} />}
              />
              <FieldSet label="Notification URL" name="notification_url" />
              <FieldSet
                label="Duplicate Block Interval"
                name="transaction_duplicates_block_interval"
                placeholder={3600}
              />
              <FieldSet label="Minimum Amount" name="min_amount" placeholder="1" />
              <FieldSet label="Maximun Amount" name="max_amount" placeholder="50000" />
              <FieldSet label="Branch" name="branch" />
              <FieldSet label="Account Number" name="account_number" />

              <FieldSet
                name="processor_type"
                label="Processor Type"
                rules={{ required: 'This field is required.' }}
                input={
                  <SelectInput
                    afterChange={(value) => selectProcessor(value)}
                    options={PROCESSORS.map((p) => ({
                      id: p.code,
                      value: p.name
                    }))}
                  />
                }
              />
              {processorType?.code ? (
                processorType.credentials.map((credential) => (
                  <FieldSet
                    name={`credentials[${credential.name}]`}
                    label={credential.humanName}
                    rules={credential.rules}
                    input={<PasswordInput visible={false} />}
                  />
                ))
              ) : (
                <FieldSet
                  label="Credentials"
                  input={<span className="text-sm text-gray-500">Please select a type above first...</span>}
                />
              )}

              <SwitchInput name="enabled" label="Processor is enabled?" />
              <SwitchInput name="third_party_deposit_block" label="Third party block?" />
              <SwitchInput name="bureau_validation" label="Bureau validation?" />
              <SwitchInput name="document_number_required" label="Document number required?" />

              <HiddenInput name="industry" value="ecommerce" />
              <HiddenInput name="mcc" value="7995" />
              {merchantId && <HiddenInput name="merchant_id" value={merchantId} />}
              <SwitchInput name="address_required" label="Address required?" />
              <SwitchInput name="auto_capture" label="Auto capture?" />
              <SwitchInput name="email_required" label="Email required?" />
              <SwitchInput name="name_required" label="Name required?" />
              <SwitchInput name="phone_required" label="Phone required?" />
            </Form>
          </SidePanelBody>
          <SidePanelFooter>
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-chill-500 focus:ring-offset-2"
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex justify-center rounded-md border border-transparent bg-chill-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-chill-700 focus:outline-none focus:ring-2 focus:ring-chill-500 focus:ring-offset-2"
              onClick={() => {
                submitRef.current.click()
              }}
            >
              Save
            </button>
          </SidePanelFooter>
        </div>
      </SidePanel>
    </div>
  )
}
